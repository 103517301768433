<template>
    <div class="iframe-container">
    <!-- 다운로드 버튼 추가 -->
        <div class="download-btn-wrapper">
          <a href="https://drive.google.com/file/d/1cw2-1z556z2xfMe5UC8y9eAbZxaHZ-ww/view?usp=sharing" target="_blank" download>
            <BButton class="download-btn">API연동 규격서 다운로드</BButton>
          </a>
        </div>
        <iframe src="https://api.building-energy.org/api-docs-edoc/"></iframe><br/>
        <div class="banner-wrapper">
            <h2 class="banner-title">건물에너지 검진 기술 외부 연계 프로그램 (다운로드)</h2>
            <div class="banner-container">
            <a href="http://cps.kict-water.com:8082/d/f86b2994-ecc7-444d-bf73-92bdbb38ec95/water-energy-index-final?orgId=1&var-bld_purpose=%EC%97%85%EB%AC%B4%EC%8B%9C%EC%84%A4&var-year=2021&var-month=1&from=1731297079907&to=1731318679907" target="_blank" class="banner-box">
              <h3>물-사용량 상관관계 분석 프로그램</h3>
              <p>물 사용량과 관련된 다양한 분석을 진행합니다.</p>
            </a>
            <a href="https://drive.google.com/file/d/1Du1r2OVJyCrbeeqPLcyzDSd6AXDlAnrl/view?usp=drive_link"  target="_blank" class="banner-box">
              <h3>외피 취약부위 판별 프로그램</h3>
              <p>건물 외피의 취약부위를 자동으로 탐지합니다.</p>
            </a>
          </div>
        </div>
    </div>
    
</template>
<script>
import NavGnb from '@/layouts/components/NavGnb.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import vSelect from 'vue-select';
import { BFormSelect, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  data() {
    return {};
  },
};
</script>
<style>
iframe {
  background: #fff;
  border: 0px; /* Reset default border */
  height: 800px; /* Viewport-relative units */
  width: 1280px; 
}

.iframe-container {
   text-align: center; /* 가로 중앙 정렬 */
}

/* 버튼을 상단 오른쪽에 고정 */
.download-btn-wrapper {
  position: fixed;
  top: 100px;
  right: 15%;
  z-index: 999;
}

/* 녹색 배경에 하얀색 글씨 스타일 */
.download-btn {
  background-color: #28a745; /* 녹색 */
  color: #ffffff; /* 하얀색 글씨 */
  border: none;
  padding: 5px 20px;
  border-radius: 20px; /* 둥근 모서리 */
  font-size: 16px;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #218838; /* 버튼 호버 시 어두운 녹색 */
  color: white;
}

.download-btn-wrapper a {
  text-decoration: none;
}

/* 배너 전체 래퍼 */
.banner-wrapper {
  background-color: #ffffff;
  padding-bottom: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

/* 제목 스타일 */
.banner-title {
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
  font-size: 18px;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}
/* 배너 컨테이너 */
.banner-container {
  display: flex;
  justify-content: center; /* 배너를 가운데 정렬 */
  align-items: center;
  gap: 10px; /* 배너 간격 설정 */
  padding: 10px;
}

/* 개별 배너 박스 스타일 */
.banner-box {
  flex: 1;
  max-width: 400px;
  background-color: rgb(192, 192, 192); 
  color: #ffffff; /* 하얀색 글씨 */
  text-align: center;
  padding: 2px;
  margin: 2px;
  text-decoration: none;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.banner-box h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.banner-box p {
  font-size: 14px;
}

</style>
